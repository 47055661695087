export const studentData = [
  {
    studentName: "StudentA",
    image: "http://localhost:8080/images/fungzinner20240711145833.jpg",
  },

  {
    studentName: "StudentB",
    image: "http://localhost:8080/images/ipadams20240710104753.jpg",
  },
];
